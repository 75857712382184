<template>
  <div class="tag-box">
    <div class="tag-list">
      <div class="tag-list-header">
        <el-input size="mini" @input="changeKeyWord" @keyup.enter.native="search" placeholder="请输入标签名称" v-model="keyword" class="input-with-select">
          <el-button slot="append" @click="search" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <div class="tag-list-content">
        <el-tree
          v-show="keyword == '' && !searching"
          :props="defaultProps"
          :data="tagsList"
          :default-checked-keys="defaultCheckedTags"
          show-checkbox
          node-key="id"
          ref="tree"
          @check-change="handleCheckChange"
        >
        </el-tree>
        <el-checkbox-group @change="changeCheckedSearchResList" v-show="keyword != '' && searching" v-model="checkedSearchResList">
          <el-checkbox v-for="item in searchResList" :key="'id_'+ item.id" :name="item.id" :label="item.id">{{item.name}}</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
    <div class="tag-selected">
      <div class="tag-selected-header">已选择标签</div>
      <div v-for="(tag,index) in tags" :key="index" class="checkTags">
        <div class="tagName">{{tag.name}}</div>
        <i class="el-icon-close" @click="deleteTag(tag.id)"></i>
      </div>
      <div class="tag-selected-content">
      </div>
    </div>
  </div>
</template>

<script>
import { formatTree } from "./formatTree";
export default {
  props:['defaultCheckedTags'],
  data() {
    return {
      searchResList:[],
      checkedSearchResList: [],
      defaultProps: {
        label: "name",
        children: "tags",
      },
      tagsList: [],
      tags:[],
      keyword: "",
      searching: false
    };
  },
  methods: {
    filterTagIds(ids){
        let tampTags = [];
        ids.forEach(id => {
            this.tagsList.forEach(_type => {
                _type.tags.forEach(item => {
                    if (item.id == id) {
                        tampTags.push(item);
                    }
                })
            })
        })
        return tampTags;
    },
    changeCheckedSearchResList(arr){
      console.log(this.tags);
      let tagIds = [];
      this.tags.forEach(item => {
        tagIds.push(item.id);
      })
      this.$refs.tree.setCheckedKeys(tagIds.concat(arr));
    },
    search(){
      let tampArr = [];
      this.tagsList.forEach(_type => {
        _type.tags.forEach(tag => {
          if (tag.name.indexOf(this.keyword.trim()) != -1) {
            tampArr.push(tag);
          }
        })
      })
      this.searching = true;
      this.searchResList = tampArr;
    },
    changeKeyWord(val){
      console.log(val);
      if (val.trim() == "") {
        this.searching = false;
      } else {
        this.search();
      }
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(this.$refs.tree.getCheckedKeys())
      console.log(this.tagsList)
      let ids=[]
      let values=this.$refs.tree.getCheckedKeys()
      for (let j=0;j<values.length;j++){
        for (let i=0;i<this.tagsList.length;i++) {
          this.tagsList[i].tags.forEach(tag => {
            if (tag.id === values[j]) {
              ids.push(tag)
            }
          })
        }
      }
      console.log(ids)
      this.tags=ids

    },
    handleNodeClick(data) {
      console.log(data);
    },
    loadTagsData() {
      this.FetchGet("/scrm-api/client-tag/group").then((response) => {
        console.log('tags list222:',response);
        this.tagsList = formatTree(JSON.parse(JSON.stringify(response)));
        this.tags = this.filterTagIds(this.tags);
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return resolve([{ name: "region1" }, { name: "region2" }]);
      }
      if (node.level > 3) return resolve([]);

      var hasChild;
      if (node.data.name === "region1") {
        hasChild = true;
      } else if (node.data.name === "region2") {
        hasChild = false;
      } else {
        hasChild = Math.random() > 0.5;
      }

      setTimeout(() => {
        var data;
        if (hasChild) {
          data = [
            {
              name: "zone" + this.count++,
            },
            {
              name: "zone" + this.count++,
            },
          ];
        } else {
            data = [];

        }

        resolve(data);
      }, 500);
    },
    deleteTag(id){
      console.log(id)
      console.log(this.$refs.tree.getCheckedKeys())
      for (let i=0;i<this.tags.length;i++){
        if (this.tags[i].id===id){
          this.tags.splice(i,1)
        }
      }
      let ids=[]
      console.log(this.tags)
      for (let i=0;i<this.tags.length;i++){
        ids.push(this.tags[i].id)
      }
      console.log('ids',ids)
      this.$nextTick(()=>{
        this.$refs.tree.setCheckedKeys(ids)
      })

    }
  },
  mounted(){
    this.loadTagsData();
  }
};
</script>

<style lang="less" scoped>
.tag-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .tag-list {
    width: 48%;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    padding: 0px 10px 10px;
    height: 400px;
    margin-right: 10px;
    .tag-list-header {
      height: 30px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .tag-list-content {
      overflow-x: hidden;
      overflow-y: auto;
      height: 350px;
      padding: 10px 0px;
      .el-checkbox-group{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }
  .tag-selected {
    width: 49%;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    padding: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    .tag-selected-header {
      padding-bottom: 5px;
      border-bottom: 1px solid rgb(233, 233, 233);
    }
    .tag-selected-content {
      padding: 10px 0px;
    }
    .checkTags{
      display: flex;
      justify-content: space-between;
      padding: 6px 0;
      align-items: center;
      .tagName{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .el-icon-close{
        cursor: pointer;
        color: red;
      }
    }
  }
  .buttonCheck{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
    .el-button{
      margin: 20px 0;
    }
  }
}
</style>
