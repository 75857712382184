var length = 0

function getChildren(parent,checkLength){
    var children=[];
    if (parent.children){
        children=parent.children
    }
    else {
        return
    }
    //遍历children
    for(var child of children){
        //每遍历一个子元素，就输出元素名
        length+=1
        if (child.checked){
            checkLength+=1
        }
        getChildren(child,checkLength);
    }
    return checkLength
}
function showParent(list,checkLength){

    let isMember =list.filter(m=>m.isMember==false).map((m)=>m)

    for (let i=0;i<isMember.length;i++){
        length = getChildren(isMember[i],checkLength)
        console.log(length)
        let bool = length===0
        if (bool){
            isMember[i].checked=false
           /* if (list.length > 0){
                list.forEach((item,index)=>{
                    if (item.id===isMember[i].id){
                        list.splice(index)
                    }
                })
            }*/
        }
    }
    return list
}
export {getChildren,showParent}