function formatTree(arr) {
    console.log('arr',arr)
    arr.forEach((item, index) => {
        item.indexNumber = index;
        item.labelStr = item.label + "+++___+++" + index + "+++___+++" + arr.length;
        if (item.children && item.children.length > 0) {
            formatTree(item.children);
        }
    });
    return arr;
}

export { formatTree } 