<template>

  <div>

    <div>

      <div style="display:inline-block;" class="node-left" v-for="(node,index) in data">

        <div class="node-icon">


          <div style="display: flex;justify-content: flex-start;align-items: center">

            <span v-if="!node.isMember">
            <i class="arsenal_icon arsenalsitemap1"
               style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>
          </span>

            <span v-if="node.isMember">
             <i class="arsenal_icon arsenaluser1"
                style="font-size: 14px;padding-right: 4px;display: flex;align-items: center"></i>
          </span>
          </div>
        </div>

      </div>

      <div style="display:inline-block;" class="node-content" @click="onClick">
        {{ node.name }}
      </div>

      <div style="display:inline-block;" class="node-right">

        <i class="el-icon-close" @click="onItemDelete(node)"></i>
      </div>

    </div>
  </div>
</template>
<script>


export default {
  name: 'DepartmentPanel',
  data() {
    return {}
  },
  props: {
    data: Array
  },
  methods: {
    onItemDelete(item) {
      // TODO:
    }
  }
}
</script>