const options = {
    scopeOptions: [
        { label: "用户行为", value: "ACTION" },
        { label: "获取实体", value: "ENTITY" },
        // { label: "线索来源", value: "CLUE_SOURCE" },
    ],
    preRelationOptions: [
        { label: "做过", value: "EQUAL" },
        { label: "没做过", value: "NOT_EQUAL" },
    ],
    timeQuickSelectionOptions: [
        { label: "今日", value: "today" },
        { label: "昨日", value: "yesterday" },
        { label: "本周", value: "thisWeek" },
        { label: "上周", value: "lastWeek" },
        { label: "本月", value: "thisMonth" },
        { label: "上月", value: "lastMonth" },
        { label: "去年", value: "lastYear" },
        { label: "本年", value: "thisYear" },
        { label: "过去7天", value: "Last7days" },
        { label: "过去30天", value: "Last30days" },
        { label: "过去90天", value: "Last90days" },
        { label: "上线至今", value: "upToNow" },
        { label: "具体时间段", value: "dateRange" },
    ],
    operationScopeOptions: [{ label: "咨询意图", value: "ASK_INTENT" }],
    operationrelationOptionsT: [
        { label: "是", value: "EQUAL" },
        { label: "不是", value: "NOT_EQUAL" },
    ],
    operationrelationOptionsB: [
        { label: "包含任意", value: "CONTAINS" },
        { label: "不包含任意", value: "NOT_CONTAINS" },
    ],
    entityRelationOptions: [
        {
            name: "等于",
            value: "EQUAL",
        },
        {
            name: "不等于",
            value: "NOT_EQUAL",
        },
        {
            name: "小于",
            value: "LOWER_THAN",
        },
        {
            name: "小于等于",
            value: "LOWER_THAN_OR_EQUAL",
        },
        {
            name: "大于",
            value: "GREATER_THAN",
        },
        {
            name: "大于等于",
            value: "GREATER_THAN_OR_EQUAL",
        },
        { name: "包含任意", value: "CONTAINS" },
        { name: "不包含任意", value: "NOT_CONTAINS" },
        { name: "为空", value: "EMPTY" },
        { name: "不为空", value: "NOT_EMPTY" },
        {
            name: "区间",
            value: "BETWEEN",
        },
    ],
    timesOptions: [
        {
            name: "等于",
            value: "EQUAL",
        },
        {
            name: "不等于",
            value: "NOT_EQUAL",
        },
        {
            name: "小于",
            value: "LOWER_THAN",
        },
        {
            name: "小于等于",
            value: "LOWER_THAN_OR_EQUAL",
        },
        {
            name: "大于",
            value: "GREATER_THAN",
        },
        {
            name: "大于等于",
            value: "GREATER_THAN_OR_EQUAL",
        },
        {
            name: "区间",
            value: "BETWEEN",
        },
    ],
    sourceOptions: [
        { name: "手动录入", value: "ADD_MANULLY" },
        { name: "API推送", value: "API_PUSH" },
        { name: "员工活码", value: "SCAN_CODE" },
        { name: "通过机器人提交", value: "ROBOT_PUSH" },
        { name: "智能名片", value: "VIEW_BUSINESS_CARD" },
        { name: "企业微信群中添加", value: "WECHAT_GROUP_ADD" },
        { name: "企业微信好友", value: "EWECHAT_ADD" },
        // { name: "自定义表单", value: "CUSTOM_FORM" }
    ]
}
export { options }