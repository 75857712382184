<template>
    <div class="customer-qun-box">
        <div class="qun-list">
          <div class="list-header">群列表</div>
            <el-input
                placeholder="输入群主或群名搜索"
                v-model="filterText">
            </el-input>
           <!-- <el-tree
                class="filter-tree"
                :data="data"
                :props="defaultProps"
                default-expand-all
                :filter-node-method="filterNode"
                show-checkbox
                ref="tree">
            </el-tree>-->
            <div
                    v-for="(group,index) in groupChatList"
                    :key="index" class="groupItem"
                    @click="checkGroup(group)"
                    :style="{pointerEvents:notEditTask?true:false}"
            >
                <span class="groupName">{{group.weWorkGroupChat.name}}</span>
                <i class="el-icon-check" v-if="group.checked"></i>
            </div>
            <span @click="lookMoreQun" v-if="cursor!==null" class="lookMore">查看更多</span>
        </div>
        <div class="selected-list">
          <div class="list-header">已选择的群</div>
          <div class="selected-item" v-for="(item,index) in showGroup">
            <span>{{item.weWorkGroupChat.name}}</span>
            <i class="el-icon-close" @click="deleteGroup(item)" :style="{pointerEvents:notEditTask?true:false}"></i>
          </div>
        </div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        filterText: '',
        data: [],
        defaultProps: {
          children: 'children',
          label: 'label'
        },
          check:false,
          showGroup:[]
      };
    },
      props:{
          groupChatList:{
              type:Array,
              default(){
                  return []
              }
          },
          cursor:{
              type:String,
              default() {
                  return '';
              }
          },
          notEditTask:{
              type:Boolean,
              default(){
                  return false
              }
          }
      },
    watch: {
      filterText(val) {
        this.$refs.tree.filter(val);
      }
    },

    methods: {
      filterNode(value, data) {
        if (!value) return true;
        return data.label.indexOf(value) !== -1;
      },
        checkGroup(value){
          console.log(value)
         if (value.checked){
             this.$set(value,'checked',false)
             for (let i=0;i<this.showGroup.length;i++){
                 if (this.showGroup[i].chat_id===value.chat_id){
                     this.showGroup.splice(i,1)
                 }
             }
         }
         else {
             this.$set(value,'checked',true)
             this.showGroup.push(value)
         }
        },
        deleteGroup(item){
            for (let i=0;i<this.showGroup.length;i++){
                if (this.showGroup[i].chat_id===item.chat_id){
                    this.showGroup.splice(i,1)
                }
            }
            for (let i=0;i<this.groupChatList.length;i++){
                if (this.groupChatList[i].chat_id===item.chat_id){
                    this.groupChatList[i].checked=false
                }
            }
        },
        lookMoreQun(){
          this.$emit('lookMoreQun')
        }
    },
  };
</script>

<style lang="less" scoped>
.customer-qun-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .qun-list{
    width: 48%;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: 10px;
      .groupItem{
          padding: 8px 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .groupName{
              padding-left: 4px;
          }
      }
      .lookMore{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;
          color: #366AFF;
          cursor: pointer;
      }
  }
  .selected-list{
    width: 49%;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    padding: 10px;
    height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    .selected-item{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 90%;
      margin: 10px;
      i{color:red;cursor: pointer}
    }
  }
  .list-header{
    border-bottom: 1px solid #e9e9e9;
    padding: 0px 0px 10px 0px;
    margin-bottom: 10px;
  }

}
</style>
