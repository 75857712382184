<template>
  <div class="welcomeType">
    <div class="welcome-config">
      <div class="welcome-config-textarea">
        <el-input
            type="textarea"
            placeholder="请输入文本内容，最多1000个字"
            v-model="welcomeConfig.text.content"
            maxlength="1000"
            show-word-limit
        >
        </el-input>
        <el-button type="text" v-if="selectedGroupType==='CUSTOMER_GROUP'">插入客户昵称</el-button>
      </div>
      <div class="welcome-config-file" v-for="(item,index) in welcomeConfig.attachments" :key="index">
        <div class="selected-name">
          <i class="arsenal_icon"
             :class="item.msgtype==='link'?'arsenalHTMLtubiao':item.msgtype==='image'?'arsenalimage1':item.msgtype==='video'?'arsenalshipin':item.msgtype==='miniprogram'?'arsenalxiaochengxu':item.msgtype==='botcard'?'arsenaljiqiren2':'arsenalfolder-open-fill'"></i>
          <span>{{ item.msgtype === 'link' ? item.title : item.msgtype === 'image' ? item.name : item.msgtype === 'video' ? item.name : item.msgtype === 'file' ? item.name : item.title }}</span>
        </div>
        <div class="selected-operation" @click="deleteFile(index)">
          <el-button type="text"><i class="el-icon-close"></i></el-button>
        </div>
      </div>
      <div class="welcome-config-other">
        <el-link @click="openMaterial" :underline="false" slot="reference" type="info" style="color:#366AFF;"><em
            class="el-icon-plus"></em>素材库中添加
        </el-link>
        <el-link @click="openMiniProgram" :underline="false" slot="reference" type="info" style="color:#366AFF;"
                 v-if="selectedGroupType!=='CUSTOMER_MOMENT'"><em class="el-icon-plus"></em>添加小程序
        </el-link>
        <el-link @click="openAddChat" :underline="false" slot="reference" type="info" style="color:#366AFF;"><em
            class="el-icon-plus"></em>添加机器人交互卡片
        </el-link>
      </div>
    </div>
    <!-- 从素材库中添加 -->
    <el-dialog
        title="选择素材"
        :visible.sync="materialDialogVisible"
        append-to-body
        width="60%"
        :close-on-click-modal="false"
        top="4vh"
    >
      <div>
        <Material
            @saveSelectedMaterial="saveSelectedMaterial"
            :pathUrl="dynameic"
            ref="Material"
            :selectedGroupType="selectedGroupType"
            :source="massSendCheck"
        ></Material>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="materialDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveDynamicCode">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 添加小程序 -->
    <el-dialog
        title="添加小程序"
        :visible.sync="addMiniProgramDialogVisible"
        append-to-body
        width="30%">
      <div class="add-chatbot-miniprogram-content">
        <el-input v-model="miniProgramTitle" placeholder="在此输入小程序标题,至少输入四个汉字"></el-input>
        <div style="display:flex;justify-content: flex-start;">

          <el-input v-model="miniProgramAppID" placeholder="在此输入小程序APPID">
          </el-input>
          <!--                    <el-popover
                                      placement="top-start"
                                      width="200"
                                      trigger="hover"
                                      content="小程序appid，必须是关联到企业的小程序应用">
                                  <i slot="reference" class="el-icon-warning" style="font-size: 18px;line-height: 40px"></i>
                              </el-popover>-->
        </div>
        <el-input v-model="miniProgramOrigin" placeholder="在此输入小程序原始ID">
        </el-input>
        <el-input v-model="miniProgramPage" placeholder="在此输入小程序page路径"></el-input>
        <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :http-request="aliyunOssRequest"
            :on-success="successUpload"
            :file-list="fileList"
            :limit=1
            list-type="picture"
            accept=".jpg,.png,.jpeg"
        >
          <el-button size="small" type="primary">点击上传缩略图</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="addMiniProgramDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addMiniProgram">确 定</el-button>
            </span>
    </el-dialog>
    <!--添加交互卡片-->
    <el-dialog
        title="添加机器人交互卡片"
        :visible.sync="addChatbotDialogVisible"
        append-to-body
        width="30%">
      <div class="add-chatbot-miniprogram-content">
        <!--<el-cascader
                placeholder="选择交互对话机器人"
                :options="options"
                filterable></el-cascader>-->
        <el-select
            v-model="chatBotInfo.apiKey"
            placeholder="选择交互对话机器人"
            style="width: 100%;"
            clearable
            @change="getIntent"
        >
          <el-option
              v-for="bot in botList"
              :key="bot.apiKey"
              :value="bot.apiKey"
              :label="bot.name"
          ></el-option>
        </el-select>
        <el-cascader
            placeholder="选择意图"
            style="width: 100%"
            v-model="chatBotInfo.initIntentId"
            :options="intentList"
            :props="defaultProps"
            :key="isKey"
            clearable
            :show-all-levels="false"
            ref="cascaderIntent"
        >

        </el-cascader>
        <el-input
            v-model="chatBotInfo.title"
            placeholder="在此输入标题"
            maxlength="30"
            show-word-limit
        ></el-input>
        <el-input
            type="textarea"
            :rows="2"
            placeholder="在此输入摘要"
            maxlength="120"
            v-model="chatBotInfo.desc"
            show-word-limit
        >
        </el-input>
        <el-upload
            class="upload-demo"
            action="https://jsonplaceholder.typicode.com/posts/"
            :on-preview="handlePreview"
            :http-request="aliyunOssRequest"
            :on-success="successUploadChat"
            :file-list="fileList"
            :on-remove="handleRemove"
            list-type="picture"
            accept=".jpg,.png,.jpeg"
        >
          <el-button size="small" type="primary">点击上传缩略图</el-button>
        </el-upload>
      </div>
      <span slot="footer" class="dialog-footer">
                <el-button @click="addChatbotDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addChatBot">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import Material from './materialSelection'
import {ossConfig, upload} from "../utils/AliyunlssUtil";
import {redirectUri} from "../../../const/redirectUri";

export default {
  name: "welcomeType",
  data() {
    return {
      dynameic: 'dynameic',
      materialDialogVisible: false,
      addMiniProgramDialogVisible: false,
      addChatbotDialogVisible: false,
      miniProgramTitle: '',
      miniProgramAppID: '',
      miniProgramOrigin: '',
      miniProgramPage: '',
      fileList: [],
      material: {},
      info: {},
      chatbotTypeDesc: '',
      chatbotTypeTitle: '',
      options: [],
      chatBotInfo: {
        title: '',
        picurl: '',
        desc: '',
        apiKey: '',
        initIntentId: ''
      },
      botApiKey: '',
      intentList: [],
      defaultProps: {
        label: 'name',
        value: 'id',
        emitPath: false,
        expandTrigger: 'hover'
      },
      isKey: 1,
      massSendCheck: 'massSend'
    }
  },
  props: ['welcomeConfig', 'selectedGroupType', 'selectedAgentId', 'botList'],
  components: {Material},
  methods: {
    saveSelectedMaterial(info) {

      this.material = info
      console.log('material', this.material)
    },
    saveDynamicCode() {
      let infomation = {}
      console.log(redirectUri[process.env.VUE_APP_CONFIG_ENV].massSend)
      if (this.material.type === 'NEWS') {
        infomation.desc = ''
        infomation.picurl = ''
        infomation.title = this.material.content.title
        infomation.desc = this.material.content.summary
        infomation.picurl = this.material.content.cover
        infomation.url = redirectUri[process.env.VUE_APP_CONFIG_ENV].massSend + '/article.html?id=' + this.material.id
        infomation.materialId = this.material.id
        infomation.msgtype = 'link'

      }
      if (this.material.type === 'LINK_NEWS') {
        infomation.desc = ''
        infomation.picurl = ''
        infomation.title = this.material.content.title
        infomation.desc = this.material.content.summary
        infomation.picurl = this.material.content.cover
        infomation.url = this.material.content.url
        infomation.materialId = this.material.id
        infomation.msgtype = 'link'
      } else if (this.material.type === 'IMAGE') {
        infomation.name = ''
        infomation.url = ''
        /*  var imgtype = this.material.content.name.toLowerCase().split('.');*/
        var index = this.material.content.url.lastIndexOf("/")
        let name = this.material.content.url.substring(index + 1, this.material.content.url.length)
        console.log(this.material.content.url.substring(index + 1, this.material.content.url.length))
        var imgtype = name.toLowerCase().split('.')
        let type = imgtype[1].toLowerCase()
        let size = this.material.content.size / 1024 / 1024 <= 2
        if (type !== 'jpg' && type !== 'png') {
          this.$message({
            message: '只能选择JPG,PNG格式的图片素材',
            type: 'warning',
            duration: 2000
          })
          return
        }
        if (!size) {
          this.$message({
            message: '选择的图片大小不能超过2M',
            type: 'warning',
            duration: 2000
          })
          return
        }
        infomation.name = this.material.content.name
        infomation.url = this.material.content.url
        infomation.materialId = this.material.id
        infomation.drawnComponents = this.material.content.drawnComponents
        infomation.msgtype = 'image'
        infomation.drawnComponents=this.material.content.drawnComponents
      } else if (this.material.type === 'VIDEO') {
        infomation.url = ''
        var index = this.material.content.url.lastIndexOf("/")
        let name = this.material.content.url.substring(index + 1, this.material.content.url.length)
        console.log(this.material.content.url.substring(index + 1, this.material.content.url.length))
        var imgtype = name.toLowerCase().split('.')
        let type = imgtype[1].toLowerCase()
        let size = this.material.content.size / 1024 / 1024 <= 10
        if (type !== 'mp4') {
          this.$message({
            message: '只能选择MP4格式的视频素材',
            type: 'warning',
            duration: 2000
          })
          return
        }
        if (!size) {
          this.$message({
            message: '选择的视频素材不能超过10M',
            type: 'warning',
            duration: 2000
          })
          return
        }
        infomation.url = this.material.content.url
        infomation.materialId = this.material.id
        infomation.msgtype = 'video'
        infomation.name = this.material.content.name
      } else if (this.material.type === 'TEXT') {
        this.welcomeConfig.text = this.material.content
      } else if (this.material.type === 'FILE') {
        console.log(this.material)
        let size = this.material.content.size / 1024 / 1024 <= 20
        if (!size) {
          this.$message({
            message: '选择的文件素材不能超过20M',
            type: 'warning',
            duration: 2000
          })
          return
        }
        infomation.url = this.material.content.url
        infomation.msgtype = 'file'
        infomation.name = this.material.content.name
        infomation.materialId = this.material.id
      }
      if (JSON.stringify(infomation) !== "{}") {
        this.welcomeConfig.attachments.push(infomation)
      }
      console.log('infomation', infomation)
      this.$nextTick(() => {
        let type = this.$refs.Material.currentMaterialType
        console.log(type)
        console.log(this.material.content)
        if (type === 'TEXT') {
          if (!this.material.content) {
            this.$message({
              message: '请选择素材',
              duration: 2000,
              type: 'warning'
            })
            return;
          }
        } else {
          if (JSON.stringify(infomation) === '{}') {
            this.$message({
              message: '请选择素材',
              duration: 2000,
              type: 'warning'
            })
            return;
          }
        }
        this.materialDialogVisible = false
      })
    },
    openMaterial() {
      this.materialDialogVisible = true
      setTimeout(() => {
        this.$refs.Material.currentSelectedDiv = 'null'
      }, 200)
      this.material = {}
    },
    openMiniProgram() {
      this.addMiniProgramDialogVisible = true
      this.miniProgramTitle = ''
      this.miniProgramAppID = ''
      this.miniProgramOrigin = ''
      this.miniProgramPage = ''
      this.fileList = []
      this.info.picUrl = ''
    },
    openAddChat() {
      if (!this.selectedAgentId) {
        this.$message({
          message: '请先选择发送应用',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      this.addChatbotDialogVisible = true
      this.chatBotInfo.apiKey = ''
      this.chatBotInfo.initIntentId = ''
      this.chatBotInfo.desc = ''
      this.chatBotInfo.title = ''
      this.chatBotInfo.picurl = ''
      this.fileList = []
      console.log(this.chatBotInfo)
    },
    handlePreview() {

    },
    handleRemove() {
      this.fileList = []
      this.chatBotInfo.picurl = ''
      this.info.picUrl = ''
    },
    deleteFile(index) {
      this.welcomeConfig.attachments.splice(index, 1)
    },
    addMiniProgram() {
      if (this.miniProgramTitle === '') {
        this.$message({
          message: '请填写小程序标题',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      if (this.miniProgramTitle.length < 4){
        this.$message({
          message: '小程序标题至少是4个汉字',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      if (this.miniProgramAppID === '') {
        this.$message({
          message: '请填写小程序APPID',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      if (this.miniProgramOrigin === '') {
        this.$message({
          message: '请填写小程序原始ID',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      if (this.miniProgramPage === '') {
        this.$message({
          message: '请填写小程序路径',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      console.log('小程序图', this.info.picUrl)
      if (this.info.picUrl === '' || this.info.picUrl === undefined) {
        this.$message({
          message: '请上传小程序的缩略图',
          duration: 2000,
          type: 'warning'
        })
        return
      }
      this.info.title = this.miniProgramTitle
      this.info.appid = this.miniProgramAppID
      this.info.origin = this.miniProgramOrigin
      this.info.page = this.miniProgramPage
      this.info.msgtype = 'miniprogram'
      let info = JSON.parse(JSON.stringify(this.info))
      this.welcomeConfig.attachments.push(info)
      console.log(this.welcomeConfig.attachments)
      this.addMiniProgramDialogVisible = false
    },
    addChatBot() {
      if (this.chatBotInfo.apiKey === '') {
        this.$message({
          message: '请选择交互机器人',
          type: 'warning',
          duration: 2000
        })
        return
      }
      if (this.chatBotInfo.initIntentId === '') {
        this.$message({
          message: '请选择意图',
          type: 'warning',
          duration: 2000
        })
        return;
      }
      if (this.chatBotInfo.title === '') {
        this.$message({
          message: '请输入标题',
          type: 'warning',
          duration: 2000
        })
        return;
      }
      if (this.chatBotInfo.desc === '') {
        this.$message({
          message: '请输入摘要',
          type: 'warning',
          duration: 2000
        })
        return;
      }
      console.log('机器人图', this.chatBotInfo.picurl)
      if (this.chatBotInfo.picurl === '' || this.chatBotInfo.picurl === undefined) {
        this.$message({
          message: '请上传缩略图',
          type: 'warning',
          duration: 2000
        })
        return;
      }
      this.chatBotInfo.msgtype = 'botcard'
      console.log(this.welcomeConfig.attachments)
      let chat = JSON.parse(JSON.stringify(this.chatBotInfo))
      this.welcomeConfig.attachments.push(chat)
      this.addChatbotDialogVisible = false
    },
    aliyunOssRequest(data) {
      let file = data.file;
      const fileName = file.name;
      const fileType = fileName.substring(fileName.lastIndexOf('.'));
      if (fileType !== '.jpg' && fileType !== '.png' && fileType !== '.jpeg') {
        this.$message({
          message: '请选择JPG,PNG格式的图片',
          duration: 2000,
          type: 'warning'
        })
        this.fileList = []
        return

      }
      let res = upload(ossConfig, file);
      console.debug('oss upload res', data, res);
      return res;
    },
    successUpload(res, file) {
      console.log(res)
      this.info.picUrl = res.url
    },
    successUploadChat(res, file) {
      this.chatBotInfo.picurl = res.url
    },
    //获取机器人下的意图
    getIntent(value) {
      let url = '/scrm-api/intermediate/ask-bot-skill-tree?apiKey=' + value
      this.FetchGet(url).then(res => {
        this.intentList = res;
        console.log('intentList', this.intentList)
      })
      this.isKey += 1

    },
  },
  mounted() {

  }
}
</script>

<style lang="less">
@import './../../../assets/less/main/common.less';

.welcomeType {
  background-color: #ffffff;
  margin: 10px 0;

  .welcome-config-file {
    display: flex;
    align-items: center;

    .selected-name {
      margin-right: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      i {
        color: #40a9ff;
      }
    }
  }
}

.add-chatbot-miniprogram-content {
  margin-top: 10px;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  padding: 10px 10px;

  .el-cascade {
    width: 100% !important;
  }

  .el-input {
    margin-bottom: 10px;

    .el-input__inner {
      border: none !important;
      border-radius: 0px !important;
      border-bottom: 1px solid #DCDFE6 !important;
      padding-right: 45px !important;
    }
  }

  .el-textarea {
    margin-bottom: 10px;

    .el-textarea__inner {
      border: none !important;
      border-radius: 0px !important;
      border-bottom: 1px solid #DCDFE6 !important;
      padding-right: 45px !important;
    }
  }

  .el-upload {
    width: 100%;
  }
}

.el-image-viewer__canvas {
  width: 70% !important;
  margin: auto;
}

.el-image-viewer__wrapper {
  z-index: 9999 !important;
}
</style>
