<template>
    <div class="right-content">
        <div class="auto-tag-list-box">
            <!--<div class="auto-tag-list-header">
                <div class="search-box"></div>
                <div class="operation-box">
                    <el-button size="mini" type="primary" @click="addRg"
                        >新建规则
                    </el-button>
                </div>
            </div>-->
            <div class="auto-tag-list-content">
                <el-table
                    :data="rgTableData"
                    height="calc(100vh - 200px)"
                    style="width: 100%"
                >
                    <el-table-column
                        prop="name"
                        label="规则名称"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="description"
                        label="描述"
                        min-width="120"
                    >
                    </el-table-column>
                    <el-table-column
                        min-width="80"
                        prop="creator.username"
                        align="center"
                        label="创建人"
                    >
                    </el-table-column>
                    <el-table-column
                        prop="date"
                        label="最近更新时间"
                        align="center"
                        width="180"
                    >
                        <template slot-scope="scope">
                            <div>
                                {{
                                    new Date(scope.row.updateTime).Format(
                                        "yyyy-MM-dd hh:mm:ss"
                                    )
                                }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="150"
                    >
                        <template slot-scope="scope">
                            <div class="operation-button">
                                <el-switch
                                    v-model="scope.row.enable"
                                    active-color="#13ce66"
                                    inactive-color="#909399"
                                    @change="rgIsOpen($event, scope.row.id)"
                                >
                                </el-switch>

                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="编辑"
                                    placement="top"
                                >
                                    <el-button
                                        type="primary"
                                        icon="el-icon-edit"
                                        size="small"
                                        circle
                                        @click="editRg(scope.row)"
                                    ></el-button>
                                </el-tooltip>
                                <el-tooltip
                                    class="item"
                                    effect="dark"
                                    content="删除"
                                    placement="top"
                                >
                                    <el-button
                                        type="danger"
                                        icon="el-icon-delete"
                                        size="small"
                                        circle
                                        @click="deleteRg(scope.row.id)"
                                    ></el-button>
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    class="task-pagination"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="rgPage"
                    :page-sizes="[10, 15, 30, 40]"
                    :page-size="rgPageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="rgTotal"
                >
                </el-pagination>
            </div>
            <el-dialog
                title="打标签"
                :visible.sync="cutomerTagDialogVisible"
                :close-on-click-modal="false"
                width="800px"
            >
                <Tag
                    v-if="cutomerTagDialogVisible"
                    :defaultCheckedTags="defaultCheckedTags"
                    ref="tagSelection"
                ></Tag>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="cacelTags">取 消</el-button>
                    <el-button size="mini" type="primary" @click="selectTags"
                        >确 定</el-button
                    >
                </span>
            </el-dialog>
            <el-dialog
                :title="operationRgTitle"
                :visible.sync="operationRgDialogVisible"
                :close-on-click-modal="false"
                width="800px"
            >
                <div v-if="operationRgDialogVisible" class="operation-rg-content">
                    <div class="operation-rg-cell">
                        <div class="operation-rg-cell-title">规则名称</div>
                        <div class="operation-rg-cell-content">
                            <el-input
                                size="mini"
                                v-model="activeRgInfo.name"
                                placeholder="请输入规则名称"
                            ></el-input>
                        </div>
                    </div>
                    <div class="operation-rg-cell">
                        <div class="operation-rg-cell-title">描述</div>
                        <div class="operation-rg-cell-content">
                            <el-input
                                size="mini"
                                v-model="activeRgInfo.description"
                                placeholder="请输入规则描述"
                            ></el-input>
                        </div>
                    </div>
                    <div class="operation-rg-cell">
                        <div class="operation-rg-cell-title">触发条件</div>
                        <div class="operation-rg-cell-content">
                            <div
                                v-for="(condition, index) in activeRgInfo
                                    .content.conditionList"
                                :key="index"
                                class="condition-cell"
                            >
                                <span v-show="index != 0" class="el-icon-or"
                                    >或</span
                                >
                                <div class="condition-cell-inner">
                                    <div class="condition-cell-inner-content">
                                        <div
                                            v-for="(
                                                conditionCell, indexCell
                                            ) in condition"
                                            :key="indexCell"
                                            class="condition-cell-mini"
                                        >
                                            <span
                                                v-if="indexCell != 0"
                                                class="el-icon-and"
                                                >且</span
                                            >
                                            <div
                                                class="
                                                    condition-cell-mini-inner
                                                "
                                            >
                                                <div
                                                    class="
                                                        condition-cell-mini-inner-content
                                                    "
                                                >
                                                    <div class="line">
                                                        <div class="attrType">
                                                            <el-select
                                                                size="small"
                                                                v-model="
                                                                    conditionCell.scope
                                                                "
                                                                placeholder="选择属性"
                                                                @change="
                                                                    changeAttrSelect(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                            >
                                                                <el-option
                                                                    v-for="item in scopeOptions"
                                                                    :key="
                                                                        item.value
                                                                    "
                                                                    :label="
                                                                        item.label
                                                                    "
                                                                    :value="
                                                                        item.value
                                                                    "
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ENTITY'
                                                            "
                                                            class="
                                                                entitySelection
                                                            "
                                                        >
                                                            <el-cascader
                                                                placeholder="请搜索选择实体"
                                                                size="small"
                                                                :show-all-levels="
                                                                    false
                                                                "
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .entityValue
                                                                "
                                                                :options="
                                                                    entityOptions
                                                                "
                                                                @change="
                                                                    handleChangeEntity(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                filterable
                                                            ></el-cascader>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ENTITY'
                                                            "
                                                            class="
                                                                timeQuickSelection
                                                            "
                                                        >
                                                            <el-select
                                                                size="small"
                                                                @change="
                                                                    entityRelationChange(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                v-model="
                                                                    conditionCell.relation
                                                                "
                                                                placeholder="请选择"
                                                            >
                                                                <el-option
                                                                    v-for="item in entityRelationOptions"
                                                                    :key="
                                                                        item.value
                                                                    "
                                                                    :label="
                                                                        $t('common.' + item.value)
                                                                    "
                                                                    :value="
                                                                        item.value
                                                                    "
                                                                ></el-option>
                                                            </el-select>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                    'ENTITY' &&
                                                                conditionCell.relation !=
                                                                    'NOT_EMPTY' &&
                                                                conditionCell.relation !=
                                                                    'EMPTY' &&
                                                                conditionCell.relation !=
                                                                    'BETWEEN'
                                                            "
                                                            class="
                                                                inputOrSearchEntity
                                                            "
                                                        >
                                                            <el-autocomplete
                                                                size="small"
                                                                class="
                                                                    inline-input
                                                                "
                                                                v-model="
                                                                    conditionCell.value
                                                                "
                                                                @input="
                                                                    handleEntityValueChange(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                @select="
                                                                    handleEntityValueSelect(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                :fetch-suggestions="
                                                                    (
                                                                        queryString,
                                                                        cb
                                                                    ) => {
                                                                        querySearchEntityValue(
                                                                            queryString,
                                                                            cb,
                                                                            index,
                                                                            indexCell
                                                                        );
                                                                    }
                                                                "
                                                                placeholder="请输入或选择实体值"
                                                            ></el-autocomplete>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                    'ENTITY' &&
                                                                conditionCell.relation ==
                                                                    'BETWEEN'
                                                            "
                                                            class="
                                                                numberSelection
                                                            "
                                                        >
                                                            <el-input-number
                                                                size="small"
                                                                v-model="
                                                                    conditionCell
                                                                        .betweenValue[0]
                                                                "
                                                                placeholder="数值"
                                                                :controls="false"
                                                                :min="0"
                                                                :max="
                                                                    1000000000000000
                                                                "
                                                            ></el-input-number>
                                                            <span class="toPre">
                                                                —
                                                            </span>
                                                            <el-input-number
                                                                size="small"
                                                                v-model="
                                                                    conditionCell
                                                                        .betweenValue[1]
                                                                "
                                                                placeholder="数值"
                                                                :controls="false"
                                                                :min="0"
                                                                :max="
                                                                    1000000000000000
                                                                "
                                                            ></el-input-number>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            class="
                                                                timeQuickSelection
                                                            "
                                                        >
                                                            <el-select
                                                                size="small"
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .time
                                                                "
                                                                @change="
                                                                    timeQuickChange(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                            >
                                                                <el-option
                                                                    v-for="item in timeQuickSelectionOptions"
                                                                    :key="
                                                                        item.value
                                                                    "
                                                                    :label="
                                                                        item.label
                                                                    "
                                                                    :value="
                                                                        item.value
                                                                    "
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                    'ACTION' &&
                                                                conditionCell
                                                                    .ext.time ==
                                                                    'dateRange'
                                                            "
                                                            class="actionTime"
                                                        >
                                                            <el-date-picker
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .actionTime
                                                                "
                                                                @input="
                                                                    actionTimeChange(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                size="small"
                                                                type="daterange"
                                                                align="right"
                                                                range-separator="至"
                                                                start-placeholder="开始日期"
                                                                end-placeholder="结束日期"
                                                            >
                                                            </el-date-picker>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'CLUE_SOURCE'
                                                            "
                                                            class="line-3"
                                                        >
                                                            <Source
                                                                :conditionCell="
                                                                    conditionCell
                                                                "
                                                                :indexCell="
                                                                    indexCell
                                                                "
                                                                :index="index"
                                                                ref="consuSource"
                                                            ></Source>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            style="display:flex"
                                                        >
                                                            <div
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    false
                                                                "
                                                                class="relation"
                                                            >
                                                                <el-select
                                                                    size="small"
                                                                    @change="
                                                                        preRelationChange(
                                                                            $event,
                                                                            index,
                                                                            indexCell
                                                                        )
                                                                    "
                                                                    v-model="
                                                                        conditionCell.relation
                                                                    "
                                                                >
                                                                    <el-option
                                                                        v-for="item in preRelationOptions"
                                                                        :key="
                                                                            item.value
                                                                        "
                                                                        :label="
                                                                            item.label
                                                                        "
                                                                        :value="
                                                                            item.value
                                                                        "
                                                                    >
                                                                    </el-option>
                                                                </el-select>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                    'ACTION'
                                                                "
                                                                class="
                                                                    timeQuickSelection
                                                                "
                                                            >
                                                                <el-select
                                                                    size="small"
                                                                    v-model="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .scope
                                                                    "
                                                                >
                                                                    <el-option
                                                                        v-for="item in operationScopeOptions"
                                                                        :key="
                                                                            item.value
                                                                        "
                                                                        :label="
                                                                            item.label
                                                                        "
                                                                        :value="
                                                                            item.value
                                                                        "
                                                                    >
                                                                    </el-option>
                                                                </el-select>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    false
                                                                "
                                                                class="
                                                                    timeQuickSelection
                                                                "
                                                            >
                                                                <el-select
                                                                    size="small"
                                                                    @change="
                                                                        operationrelationChange(
                                                                            $event,
                                                                            index,
                                                                            indexCell
                                                                        )
                                                                    "
                                                                    v-model="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .relation
                                                                    "
                                                                >
                                                                    <el-option
                                                                        v-for="item in operationrelationOptions"
                                                                        :key="
                                                                            item.value
                                                                        "
                                                                        :label="
                                                                            item.label
                                                                        "
                                                                        :value="
                                                                            item.value
                                                                        "
                                                                    >
                                                                    </el-option>
                                                                </el-select>
                                                            </div>
                                                            <div
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                    'ACTION'
                                                                "
                                                                class="
                                                                    intentSelection
                                                                "
                                                            >
                                                                <el-cascader
                                                                    size="small"
                                                                    :show-all-levels="
                                                                        false
                                                                    "
                                                                    :props="
                                                                        defaultProps
                                                                    "
                                                                    v-model="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .value
                                                                    "
                                                                    v-if="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .relation ==
                                                                            'EQUAL' ||
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .relation ==
                                                                            'NOT_EQUAL'
                                                                    "
                                                                    placeholder="单选意图"
                                                                    :options="
                                                                        botIntentionOptions
                                                                    "
                                                                    filterable
                                                                ></el-cascader>
                                                                <el-cascader
                                                                    size="mini"
                                                                    :show-all-levels="
                                                                        false
                                                                    "
                                                                    v-model="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .ext
                                                                            .tempValue
                                                                    "
                                                                    placeholder="多选意图"
                                                                    v-if="
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .relation ==
                                                                            'CONTAINS' ||
                                                                        conditionCell
                                                                            .ext
                                                                            .conditions[0]
                                                                            .relation ==
                                                                            'NOT_CONTAINS'
                                                                    "
                                                                    :options="
                                                                        botIntentionOptions
                                                                    "
                                                                    :props="{
                                                                        multiple: true,
                                                                        value: 'id',
                                                                        label: 'name',
                                                                    }"
                                                                    filterable
                                                                ></el-cascader>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div
                                                        v-if="
                                                            conditionCell.scope ==
                                                            'ACTION'
                                                        "
                                                        class="line line-2"
                                                    >
                                                        <span
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            class="timesTitle"
                                                            >次数</span
                                                        >
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            class="
                                                                timeQuickSelection
                                                            "
                                                        >
                                                            <el-select
                                                                size="small"
                                                                @change="
                                                                    timesOptionsChange(
                                                                        $event,
                                                                        index,
                                                                        indexCell
                                                                    )
                                                                "
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .relation
                                                                "
                                                            >
                                                                <el-option
                                                                    v-for="item in timesOptions"
                                                                    :key="
                                                                        item.value
                                                                    "
                                                                    :label="
                                                                        $t('common.' + item.value)
                                                                    "
                                                                    :value="
                                                                        item.value
                                                                    "
                                                                >
                                                                </el-option>
                                                            </el-select>
                                                        </div>
                                                        <div
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            class="
                                                                numberSelection
                                                            "
                                                        >
                                                            <el-input-number
                                                                size="small"
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .relation !=
                                                                        'BETWEEN'
                                                                "
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .value
                                                                "
                                                                placeholder="数值"
                                                                :controls="false"
                                                                :min="0"
                                                                :max="
                                                                    1000000000000000
                                                                "
                                                            ></el-input-number>
                                                            <el-input-number
                                                                size="small"
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .relation ==
                                                                        'BETWEEN'
                                                                "
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .betweenValue[0]
                                                                "
                                                                placeholder="数值"
                                                                :controls="false"
                                                                :min="0"
                                                                :max="
                                                                    1000000000000000
                                                                "
                                                            ></el-input-number>
                                                            <span
                                                                class="toPre"
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .relation ==
                                                                        'BETWEEN'
                                                                "
                                                            >
                                                                —
                                                            </span>
                                                            <el-input-number
                                                                size="small"
                                                                v-if="
                                                                    conditionCell.scope ==
                                                                        'ACTION' &&
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .relation ==
                                                                        'BETWEEN'
                                                                "
                                                                v-model="
                                                                    conditionCell
                                                                        .ext
                                                                        .conditions[1]
                                                                        .betweenValue[1]
                                                                "
                                                                placeholder="数值"
                                                                :controls="false"
                                                                :min="0"
                                                                :max="
                                                                    1000000000000000
                                                                "
                                                            ></el-input-number>
                                                        </div>
                                                        <span
                                                            v-if="
                                                                conditionCell.scope ==
                                                                'ACTION'
                                                            "
                                                            class="timesTitle"
                                                            >次</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    class="
                                                        el-icon-delete-box-mini
                                                    "
                                                >
                                                    <span
                                                        v-if="
                                                            condition.length > 1
                                                        "
                                                        @click="
                                                            deleteConditionInner(
                                                                index,
                                                                indexCell
                                                            )
                                                        "
                                                        class="el-icon-remove"
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="el-icon-circle-plus-box">
                                            <span
                                                @click="
                                                    addConditionInner(index)
                                                "
                                                class="el-icon-circle-plus"
                                            ></span>
                                        </div>
                                    </div>
                                    <div class="el-icon-delete-box">
                                        <span
                                            v-if="
                                                activeRgInfo.content
                                                    .conditionList.length > 1
                                            "
                                            @click="deleteConditionOuter(index)"
                                            class="el-icon-delete"
                                        ></span>
                                    </div>
                                </div>
                            </div>
                            <div class="el-icon-plus-box">
                                <span
                                    @click="addConditionOuter"
                                    class="el-icon-circle-plus"
                                ></span>
                            </div>
                        </div>
                    </div>
                    <div class="operation-rg-cell">
                        <div class="operation-rg-cell-title tagopt">
                            <span class="pre">打标签</span>
                            <span @click="addTag" class="el-icon-edit"></span>
                        </div>
                        <div class="operation-rg-cell-content">
                            <div class="tags-box">
                                <el-tag
                                    v-for="item in filterTagIds(
                                        activeRgInfo.content.tagIds
                                    )"
                                    :key="item.id"
                                    effect="light"
                                    size="mini"
                                >
                                    {{ item.name }}
                                </el-tag>
                            </div>
                        </div>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="mini" @click="cacelOperationRg"
                        >取 消</el-button
                    >
                    <el-button size="mini" type="primary" @click="submitAddRg"
                        >确 定</el-button
                    >
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Tag from "./tagSelection";
import Source from "./AutotagSource";
import { options } from "./autoTagOptions";
import { formatTree } from "./formatTree";
export default {
    components: { Tag, Source },
    data() {
        return {
            cutomerTagDialogVisible: false,
            defaultCheckedTags: [],
            rgTableData: [],
            operationRgTitle: "添加规则",
            operationRgDialogVisible: false,
            rgTotal: 0,
            rgPageSize: 15,
            rgPage: 1,
            activeRgInfo: {
                name: "",
                description: "",
                content: {
                    tagIds: [],
                    conditionList: [[{ scope: "" }]],
                },
            },
            defaultProps: {
                children: "children",
                label: "name",
                value: "id",
            },
            scopeOptions: options.scopeOptions,
            preRelationOptions: options.preRelationOptions,
            timeQuickSelectionOptions: options.timeQuickSelectionOptions,
            operationScopeOptions: options.operationScopeOptions,
            operationrelationOptions: options.operationrelationOptionsB,
            botIntentionOptions: [],
            entityOptions: [],
            // 实体
            allEntityValue: [],
            activeEntityValue: "",
            entityRelationOptions: options.entityRelationOptions,
            timesOptions: options.timesOptions,
            tagsList: [],
        };
    },
    methods: {
        filterTagIds(ids) {
            let tampTags = [];
            ids.forEach((id) => {
                this.tagsList.forEach((_type) => {
                    _type.tags.forEach((item) => {
                        if (item.id == id) {
                            tampTags.push(item);
                        }
                    });
                });
            });
            return tampTags;
        },
        // 选择标签
        selectTags() {
            let tampArr = [];
            this.$refs.tagSelection.tags.forEach((item) => {
                tampArr.push(item.id);
            });
            this.$set(this.activeRgInfo.content, "tagIds", tampArr);
            this.cutomerTagDialogVisible = false;
        },
        // 添加 或 条件
        addConditionOuter() {
            this.activeRgInfo.content.conditionList.push([{ scope: "" }]);
        },
        // 删除 或 条件
        deleteConditionOuter(index) {
            this.activeRgInfo.content.conditionList.splice(index, 1);
        },
        // 添加 并 条件
        addConditionInner(index) {
            this.activeRgInfo.content.conditionList[index].push([
                { scope: "" },
            ]);
        },
        // 删除 并 条件
        deleteConditionInner(index, indexCell) {
            this.activeRgInfo.content.conditionList[index].splice(indexCell, 1);
        },
        // 修改或条件
        changeAttrSelect(val, index, indexCell) {
            if (val == "ACTION") {
                this.activeRgInfo.content.conditionList[index][indexCell] = {
                    ext: {
                        time: "upToNow",
                        relationName: "做过",
                        actionTime: "",
                        conditions: [
                            {
                                scope: "ASK_INTENT",
                                relation: "CONTAINS",
                                variable: "咨询意图",
                                value: [],
                                valueType: "",
                                ext: {
                                    relationName: "",
                                    tempValue: [],
                                },
                            },
                            {
                                scope: "ASK_FREQUENCY",
                                relation: "EQUAL",
                                variable: "咨询次数",
                                value: 1,
                                betweenValue: [1, 10],
                                ext: {
                                    relationName: "",
                                },
                            },
                        ],
                    },
                    relation: "EQUAL",
                    scope: "ACTION",
                    variable: "actionTime",
                    variableType: "POINTER",
                    value: "conditions",
                    valueType: "POINTER",
                };
            }
            if (val == "ENTITY") {
                this.activeRgInfo.content.conditionList[index][indexCell] = {
                    ext: {
                        entityValue: [],
                        relationName: "",
                    },
                    scope: "ENTITY",
                    relation: "EQUAL",
                    variable: "",
                    value: "",
                    betweenValue: [1, 10],
                    time: "",
                };
            }
            if (val == "CLUE_SOURCE") {
                this.$set(
                    this.activeRgInfo.content.conditionList[index],
                    indexCell,
                    {
                        scope: "CLUE_SOURCE",
                        relation: "EQUAL",
                        value: [],
                        valueType: "ADD_MANULLY",
                        variable: "clueSource",
                    }
                );
            }
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        // 点击提交添加规则
        submitAddRg() {
            if (this.activeRgInfo.name.trim() == "") {
                this.$message.warning("请填写规则名称");
                return false;
            }
            // if (this.activeRgInfo.description.trim() == "") {
            //     this.$message.warning("请填写规则描述");
            //     return false;
            // }
            this.activeRgInfo.enable = true;
            let flag = true;
            this.activeRgInfo.content.conditionList.forEach(
                (conditionOuter) => {
                    conditionOuter.forEach((conditionInner) => {
                        if (
                            conditionInner.scope == "" ||
                            conditionInner.scope == undefined
                        ) {
                            flag = false;
                        }
                        if (conditionInner.scope == "ENTITY") {
                            if (
                                conditionInner.ext.entityValue.length == 0 ||
                                (conditionInner.relation != "BETWEEN" &&
                                    conditionInner.value == "") ||
                                (conditionInner.relation == "BETWEEN" &&
                                    conditionInner.betweenValue.filter(
                                        (item) => {
                                            return item != undefined;
                                        }
                                    ).length != 2)
                            ) {
                                flag = false;
                            } else {
                                conditionInner.variable =
                                    conditionInner.ext.entityValue[
                                        conditionInner.ext.entityValue.length -
                                            1
                                    ];
                            }
                        }
                        if (conditionInner.scope == "ACTION") {
                            if (
                                conditionInner.ext.conditions[0].ext.tempValue
                                    .length == 0 ||
                                (conditionInner.ext.conditions[1].relation !=
                                    "BETWEEN" &&
                                    conditionInner.ext.conditions[1].value ==
                                        "") ||
                                (conditionInner.ext.conditions[1].relation ==
                                    "BETWEEN" &&
                                    conditionInner.ext.conditions[1].betweenValue.filter(
                                        (item) => {
                                            return item != undefined;
                                        }
                                    ).length != 2)
                            ) {
                                flag = false;
                            } else {
                                conditionInner.ext.conditions[0].ext.tempValue.forEach(
                                    (item, index) => {
                                        this.$set(
                                            conditionInner.ext.conditions[0]
                                                .value,
                                            index,
                                            item[item.length - 1]
                                        );
                                    }
                                );
                            }
                            if (conditionInner.ext.time == "dateRange") {
                                if (conditionInner.ext.actionTime == "") {
                                    flag = false;
                                } else {
                                    if (
                                        conditionInner.ext.actionTime instanceof
                                            Array &&
                                        conditionInner.ext.actionTime.length ==
                                            2
                                    ) {
                                        conditionInner.ext.actionTimestamp = [
                                            new Date(
                                                conditionInner.ext.actionTime[0]
                                            ).getTime(),
                                            new Date(
                                                conditionInner.ext.actionTime[1]
                                            ).getTime(),
                                        ];
                                        if (
                                            conditionInner.ext
                                                .actionTimestamp[0] ==
                                            conditionInner.ext
                                                .actionTimestamp[1]
                                        ) {
                                            conditionInner.ext.actionTimestamp[1] =
                                                conditionInner.ext
                                                    .actionTimestamp[0] +
                                                (24 * 60 * 60 * 1000 - 1);
                                        }
                                    }
                                }
                            }
                        }
                        // if (conditionInner.scope == "CLUE_SOURCE") {
                        //     if (
                        //         conditionInner.valueType != "ADD_MANULLY" &&
                        //         conditionInner.value.length == 0
                        //     ) {
                        //         flag = false;
                        //     }
                        // }
                    });
                }
            );
            if (!flag) {
                this.$message.warning("请检查触发条件填写，确认填写完整");
                return false;
            }
            if (this.activeRgInfo.content.tagIds.length == 0) {
                this.$message.warning("请给当前新增规则添加标签");
                return false;
            }
            this.submitAddRgRequest();
        },
        // 添加规则接口
        submitAddRgRequest() {
            let param = JSON.parse(JSON.stringify(this.activeRgInfo));
            param.content.tagIds = param.content.tagIds.map((item) => {
                if (item.id != undefined) {
                    return item.id;
                } else {
                    return item;
                }
            });
            param.content.conditionList.forEach((cCell) => {
                cCell.forEach((iCell) => {
                    if (iCell.scope == "CLUE_SOURCE") {
                        iCell.value = JSON.stringify(iCell.value);
                    }
                });
            });
            let url = "";
            if (this.operationRgTitle == "添加规则") {
                url = this.requestUrl.autoTag.addAutoTagRule;
                this.FetchPost(url, param)
                    .then((res) => {
                        if (res.code === "0") {
                            this.$message({
                                message: "添加成功",
                                duration: 2000,
                                type: "success",
                            });
                            this.getRgList();
                            this.operationRgDialogVisible = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            } else {
                url = this.requestUrl.autoTag.updateAutoTagRule + param.id;
                this.FetchPut(url, "", param)
                    .then((res) => {
                        if (res.code === "0") {
                            this.$message({
                                message: "更新成功",
                                duration: 2000,
                                type: "success",
                            });
                            this.getRgList();
                            this.operationRgDialogVisible = false;
                        } else {
                            this.$message.error(res.message);
                        }
                    })
                    .catch((err) => {
                        this.$message(err);
                    });
            }
        },
        // 修改实体关系
        entityRelationChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][indexCell].relation =
                val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        // 快捷选择时间
        timeQuickChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][indexCell].ext.time =
                val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        actionTimeChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][
                indexCell
            ].ext.actionTime = val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        preRelationChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][indexCell].relation =
                val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        operationrelationChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][
                indexCell
            ].ext.conditions[0].relation = val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        timesOptionsChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][
                indexCell
            ].ext.conditions[1].relation = val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        entityFetchSuggestions(queryString, cb) {
            // cb([{ value: "空",label: "空",}]);
            cb([]);
        },
        // 实体值选择
        querySearchEntityValue(queryString, cb, index, indexCell) {
            console.log(queryString, cb);
            if (this.allEntityValue.length == 0) {
                let tampArr = [],
                    e =
                        this.activeRgInfo.content.conditionList[index][
                            indexCell
                        ].ext.entityValue;
                this.entityOptions.forEach((item) => {
                    if (item.value === e[0]) {
                        item.children.forEach((cell) => {
                            if (cell.value === e[1]) {
                                tampArr = cell.content;
                            }
                        });
                    }
                });
                tampArr.forEach((item) => {
                    item.value = item.standardWord;
                    item.label = item.standardWord;
                });
                this.allEntityValue = [...tampArr];
            }
            var allEntityValue = Array.from(this.allEntityValue);
            allEntityValue.unshift({ label: "空", value: "空" });
            var results = queryString
                ? allEntityValue.filter(
                      this.createFilterEntityValue(queryString)
                  )
                : allEntityValue;
            cb(results);
        },
        // 实体值筛选
        createFilterEntityValue(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        // 实体筛选
        querySearch(queryString, cb) {
            var allEntitys = this.allEntitys;
            var results = queryString
                ? allEntitys.filter(this.createFilter(queryString))
                : allEntitys;
            cb(results);
        },
        // 实体筛选
        createFilter(queryString) {
            return (restaurant) => {
                return (
                    restaurant.value
                        .toLowerCase()
                        .indexOf(queryString.toLowerCase()) === 0
                );
            };
        },
        handleChangeEntity(e, index, indexCell) {
            console.log(e, index, indexCell);
            this.activeRgInfo.content.conditionList[index][
                indexCell
            ].ext.entityValue = e;
            let tampArr = [];
            console.log(this.entityOptions);
            this.entityOptions.forEach((item) => {
                if (item.value === e[0]) {
                    console.log(item.children);
                    item.children.forEach((cell) => {
                        if (cell.value === e[1]) {
                            tampArr = cell.content;
                        }
                    });
                }
            });
            tampArr.forEach((item) => {
                item.value = item.standardWord;
                item.label = item.standardWord;
            });
            this.allEntityValue = [...tampArr];
            this.activeEntityValue = "";
        },
        handleEntityValueSelect(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][indexCell].value =
                val.value;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        handleEntityValueChange(val, index, indexCell) {
            this.activeRgInfo.content.conditionList[index][indexCell].value =
                val;
            this.activeRgInfo.content.conditionList = [
                ...this.activeRgInfo.content.conditionList,
            ];
        },
        // 获取指定知识库下所有实体
        getAllEntityBySkill() {
            this.FetchGet(this.requestUrl.autoTag.getEntityTreeWithoutNull)
                .then((res) => {
                    if (res.code === "0") {
                        let tampArr = [];
                        for (let key in res.data) {
                            tampArr.push({
                                label: key,
                                value: key,
                                children: res.data[key],
                            });
                        }
                        tampArr.forEach((item) => {
                            item.children.forEach((cell) => {
                                cell.label = cell.name;
                                cell.value = cell.name;
                            });
                        });
                        this.entityOptions = [...tampArr];
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取意图树
        getBotListIntentTree() {
            this.FetchGet(this.requestUrl.autoTag.getBotTreeWithoutNull, {})
                .then((res) => {
                    if (res.code === "0") {
                        this.botIntentionOptions = res.data;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        // 获取规则列表
        getRgList() {
            this.FetchGet(this.requestUrl.autoTag.getAutoTagRuleList, {
                page: this.rgPage,
                size: this.rgPageSize,
            })
                .then((res) => {
                    if (res.code === "0") {
                        this.rgTableData = res.data.list;
                        this.rgTotal = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                })
                .catch((err) => {
                    this.$message(err);
                });
        },
        //删除规则
        deleteRg(id) {
            this.$confirm("确定删除规则, 是否继续?", "删除规则", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    let url = this.requestUrl.autoTag.deleteAutoTagRule + id;
                    this.FetchDelete2(url).then((res) => {
                        if (res.code === "0") {
                            this.$message({
                                message: "删除规则成功",
                                duration: 2000,
                                type: "success",
                            });
                            this.getRgList();
                        } else {
                            this.$message({
                                message: res.message,
                                duration: 2000,
                                type: "error",
                            });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        // 编辑规则
        editRg(rgInfo) {
            this.operationRgTitle = "编辑规则";
            let tampRgInfo = rgInfo;
            tampRgInfo.content.conditionList.forEach((cCell) => {
                cCell.forEach((iCell) => {
                    if (iCell.scope == "CLUE_SOURCE") {
                        if (typeof iCell.value == "string") {
                            iCell.value = JSON.parse(iCell.value);
                        }
                        console.log(iCell.value);
                    }
                });
            });
            this.activeRgInfo = rgInfo;
            this.operationRgDialogVisible = true;
        },
        // 获取标签列表
        loadTagsData() {
            this.FetchGet("/scrm-api/client-tag/group").then((response) => {
                this.tagsList = formatTree(
                    JSON.parse(JSON.stringify(response))
                );
            });
        },
        // 开启/关闭规则
        rgIsOpen(value, id) {
            let url = this.requestUrl.autoTag.updateAutoTagRule + id;
            this.FetchPut(url, "", {
                enable: value,
            }).then((res) => {
                if (res.code === "0") {
                    this.$message({
                        message: "修改成功",
                        duration: 2000,
                        type: "success",
                    });
                    this.getRgList();
                } else {
                    this.$message({
                        message: res.message,
                        duration: 2000,
                        type: "error",
                    });
                }
            });
        },
        // 添加规则
        addRg() {
            this.operationRgTitle = "添加规则";
            this.operationRgDialogVisible = true;
            this.initActiveRgInfo();
        },
        // 关闭操作规则
        cacelOperationRg() {
            this.operationRgDialogVisible = false;
            this.initActiveRgInfo();
        },
        // 初始化添加规则数据
        initActiveRgInfo() {
            this.activeRgInfo = {
                name: "",
                description: "",
                content: {
                    tagIds: [],
                    conditionList: [[{ scope: "" }]],
                },
            };
        },
        // 分页条数
        handleSizeChange(val) {
            this.rgPageSize = val;
            this.getRgList();
        },
        // 切换页码
        handleCurrentChange(val) {
            this.rgPage = val;
            this.getRgList();
        },
        // 打标签
        addTag() {
            this.defaultCheckedTags = this.activeRgInfo.content.tagIds;
            this.cutomerTagDialogVisible = true;
            this.$nextTick(() => {
                this.$refs.tagSelection.tags = this.activeRgInfo.content.tagIds;
            });
        },
        // 取消打标签
        cacelTags() {
            this.defaultCheckedTags = [];
            this.cutomerTagDialogVisible = false;
        },
    },
    mounted() {
        this.getRgList();
        this.initActiveRgInfo();
        this.getBotListIntentTree();
        this.getAllEntityBySkill();
        this.loadTagsData();
    },
};
</script>

<style lang="less">
@import "../../../assets/less/fissionMarketing/autoTag.less";
</style>